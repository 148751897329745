import React from 'react'
import ContactBlock from './contactBlock'
import Footer from './footer'

const footerBlock = () => {
	return (
		<div className="bg-[#1e2142] radial-gradient-background">
			<ContactBlock />
			<hr className="opacity-20 w-11/12 mx-auto" />
			<Footer />
		</div>
	)
}
export default footerBlock
