import React from 'react'
import { useState } from 'react'
import FooterBlock from './footer/footerBlock'
import SiteHeader from './siteHeader'

const Layout = ({ isHomePage, children }) => {
	const [isMenuOpen, setMenuOpen] = useState(false)
	
	return (
		<div className="min-h-screen max-h-screen min-w-screen max-w-screen">
			<SiteHeader 
				isHomePage={isHomePage}
				isMenuOpen={isMenuOpen}
				setMenuOpen={setMenuOpen}
			/>
			{/* offset wrapper div to allow for sticky header on mobile.*/}
			<div className="relative top-[82px] lg:top-[92px] xl:top-[114px]" aria-hidden={isMenuOpen}>
				<main className="mb-auto">{children}</main>
				<FooterBlock />
			</div>
		</div>
	)
}

export default Layout
