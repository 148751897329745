import React, { useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link, navigate } from 'gatsby'

const ProjectCard = props => {
	const { title, label, slug, services, cardImage } = props
	const [isActive, setIsActive] = useState(false)
	const serviceTags = services?.nodes?.map(tag => (
		<li
			className="font-mono text-sm uppercase tracking-wider inline list-none inline-services-style"
			key={tag.id}
		>
			{tag.name}
		</li>
	))

	const imageData = {
		gatsbyImage: cardImage?.localFile?.childImageSharp.gatsbyImageData,
		altText: cardImage?.altText || title,
	}

	return (
		<div
			className="text-2xl cursor-pointer relative w-full py-6 md:p-0 max-w-lg inline-block text-left opacity-80 hover:opacity-100 transition-all duration-300"
			onClick={() => {
				navigate(`/our-work/${slug}`)
			}}
			onMouseEnter={() => {
				setIsActive(true)
			}}
			onMouseLeave={() => {
				setIsActive(false)
			}}
		>
			{imageData.gatsbyImage && (
				<div className="relative w-full overflow-hidden mb-6">
					<GatsbyImage
						priority="true"
						image={imageData.gatsbyImage}
						aspectRatio={20 / 23}
						layout="fullWidth"
						objectFit="cover"
						alt=""
					/>
				</div>
			)}
			<div>
				<span className="h4 block pt-1 tracking-wider">{label}</span>
				<Link to={`/our-work/${slug}`}>
					<h3
						className={`leading-7 md:leading-9 xl:leading-10 pb-5 text-[calc(1.65rem+(100vw-975px)/200)] ${
							isActive ? 'underline' : ''
						}`}
					>
						{title}
					</h3>
				</Link>
				<ul className="pb-6 md:pb-4 leading-4">{serviceTags}</ul>
			</div>
		</div>
	)
}
export default ProjectCard
