import React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import ProjectCard from '../components/page_blocks/projectCard'
import Seo from '../components/seo'

const ProjectsPage = ({ data }) => {
	return (
		<Layout>
			<div className="dark-background">
				<div className="min-h-[60vh] md:min-h-[72vh] bg-[url('../../static/Touchscreen-1-denoise-gigapixel.jpg')] bg-cover bg-center">
					<div className="section relative md:top-[15vh]">
						<div className="mx-auto py-4">
							<div className="md:w-4/5">
								<h1 className="h1 pb-6">Our Work</h1>
								<p className="text-white leading-9 text-base sm:text-2xl md:text-3xl">
									We love to tackle complex problems and provide simple, elegant
									solutions to help our clients exceed their goals.
								</p>
							</div>
						</div>
					</div>
				</div>

				<div className="section">
					<div className="grid md:grid-cols-2 lg:grid-cols-3 md:gap-8 xl:gap-[70px] mx-auto justify-center">
						{data.allWpPost.edges.map(({ node }) => {
							return (
								<ProjectCard
									title={node.title}
									label={node.additionalPostFields.label}
									services={node.services}
									slug={node.slug}
									cardImage={node.customImages?.cardImage}
									key={node.id}
								/>
							)
						})}
					</div>
				</div>
			</div>
		</Layout>
	)
}

export const pageQuery = graphql`
	query {
		allWpPost(sort: { fields: [isSticky, date], order: [DESC, DESC] }) {
			edges {
				node {
					...BasicProjectInfo
					...CardImage
					...ServiceInfo
					...AdditionalPostFieldsInfo
				}
			}
		}
	}
`

export default ProjectsPage

export function Head() {
	return (
		<Seo
			title="Our Work"
			description="Explore Fast Forward's previous projects"
		/>
	)
}
